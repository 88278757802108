import React from "react"
import Advantages from "../components/HomeComponents/Advantages"
import Banner from "../components/HomeComponents/Banner"
import ContactSection from "../components/HomeComponents/ContactSection"
import Partners from "../components/HomeComponents/Partners"
import Team from "../components/HomeComponents/Team"
import WhyFilecoin from "../components/HomeComponents/WhyFilecoin"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <Banner />
      <Advantages />
      <Team />
      <Partners />
      <WhyFilecoin />
      <ContactSection />
    </Layout>
  )
}
