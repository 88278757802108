import React from "react"
import { Col, Container, Row, Card } from "react-bootstrap"

const Advantages = () => {
  return (
    <section className="advantages-section">
      <Container>
        <h2 className="heading-h2 heading-h2-sm text-center text-white">
          Our <span className="text-purple">Advantage</span>
        </h2>
        <Row>
          <Col lg={4} md={6} sm={12} xs={12} className="mt-4">
            <Card className="advantage-card card-dark h-100">
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src="/assets/svgs/home.svg"
                  alt="home-icon"
                  className="mb-3"
                />
                <Card.Title className="text-large text-large-sm">
                  Proven Architecture
                </Card.Title>
                {/* <Card.Subtitle className="mb-2">Card Subtitle</Card.Subtitle> */}
                <Card.Text className="text-normal">
                  Multiple nodes using our architecture already live on Mainnet
                  under our management
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12} className="mt-4">
            <Card className="advantage-card card-dark h-100">
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src="/assets/svgs/settings.svg"
                  alt="home-icon"
                  className="mb-3"
                />
                <Card.Title className="text-large text-large-sm">
                  Full Node Configuration and Management{" "}
                </Card.Title>
                {/* <Card.Subtitle className="mb-2">Card Subtitle</Card.Subtitle> */}
                <Card.Text className="text-normal">
                  We take complete ownership of the commissioning and operation
                  of the node
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12} className="mt-4">
            <Card className="advantage-card card-dark h-100">
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src="/assets/svgs/code.svg"
                  alt="home-icon"
                  className="mb-3"
                />
                <Card.Title className="text-large text-large-sm">
                  Custom Software Toolkit{" "}
                </Card.Title>
                {/* <Card.Subtitle className="mb-2">Card Subtitle</Card.Subtitle> */}
                <Card.Text className="text-normal">
                  Our custom software toolkit includes automated FIL+ deal
                  ingestion, intuitive dashboard, alerts, and database
                  integration for complete auditability
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12} className="mt-4">
            <Card className="advantage-card card-dark h-100">
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src="/assets/svgs/diagram.svg"
                  alt="home-icon"
                  className="mb-3"
                />
                <Card.Title className="text-large text-large-sm">
                  Financial Modelling{" "}
                </Card.Title>
                {/* <Card.Subtitle className="mb-2">Card Subtitle</Card.Subtitle> */}
                <Card.Text className="text-normal">
                  Forecast earnings and plan using our sophisticated economic
                  model
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12} className="mt-4">
            <Card className="advantage-card card-dark h-100">
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src="/assets/svgs/global-network.svg"
                  alt="home-icon"
                  className="mb-3"
                />
                <Card.Title className="text-large text-large-sm">
                  Continuous R&D
                </Card.Title>
                {/* <Card.Subtitle className="mb-2">Card Subtitle</Card.Subtitle> */}
                <Card.Text className="text-normal">
                  We stay on top of the latest developments within the
                  ecosystem, including participation in early releases
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12} className="mt-4">
            <Card className="advantage-card card-dark h-100">
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src="/assets/svgs/usb-connection.svg"
                  alt="home-icon"
                  className="mb-3"
                />
                <Card.Title className="text-large text-large-sm">
                  Industry Relationships
                </Card.Title>
                {/* <Card.Subtitle className="mb-2">Card Subtitle</Card.Subtitle> */}
                <Card.Text className="text-normal">
                  Close ties with industry players, including hardware
                  distributors, lenders, Lotus software developers, and builders
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Advantages
