import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"

const Footer = () => {
  return (
    <footer className="footer-section">
      <Container>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/" className="footer-logo">
            <img src="/assets/svgs/logo.svg" alt="footer-logo" />
          </Link>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
