import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import ScrollButton from '../shared/ScrollTop'

const Layout = ({children}) => {

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
          setVisible(true)
      }
      else if (scrolled <= 300) {
          setVisible(false)
      }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);

  }, [])
  return (
    <>
      <Header />
      {children}
      {visible &&
      <ScrollButton />
      }
      <Footer />
    </>
  )
}

export default Layout
