import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const Partners = () => {
  const partnerImages = [
    "/assets/svgs/partner2.svg",
    "/assets/svgs/partner3.svg",
    "/assets/svgs/partner4.svg",
  ]
  return (
    <section className="partner-section">
      <Container>
        <h2 className="heading-h2 heading-h2-sm text-center text-white mb-5">
          Our <span className="text-purple">Partners</span>
        </h2>
        <Row style={{ rowGap: "25px" }}>
          {partnerImages.map(source => {
            return (
              <Col lg={4} md={12} sm={12} xs={12} className="image-container">
                <img src={source} alt="partner" />
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}

export default Partners
