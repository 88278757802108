import React from "react"
import { Col, Container, Row, Card } from "react-bootstrap"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

const WhyFilecoin = () => {
  const carouselBreakpoints = {
    0: { items: 1 },
    730: { items: 2 },
    1024: { items: 2 },
  }

  const whyCards = [
    <WhyCard
      heading="Leader in distributed storage"
      count="01"
      text="Protocol Labs, the team behind Filecoin and IPFS has been 
  pioneering distributed storage technology since 2013. 
  With over 18 Exabytes of storage power across 4000+ providers worldwide, 
  Filecoin is the undisputed largest and most robust distributed storage network in existence "
    />,

    <WhyCard
      heading="Backed by Venture Capital"
      count="02"
      text="Filecoin has raised greater than $200 million including
  venture capital from prominent investors such as Sequoia
  Capital, Y Combinator, and Winklevoss Capital Management"
    />,

    <WhyCard
      heading="Strong Ecosystem"
      count="03"
      text="Significant investment and talent is participating in the
  growth of this ecosystem. There is a growing number of
  platforms and applications being developed on top of
  Filecoin"
    />,

    <WhyCard
      heading="Scalable Business Model"
      count="04"
      text="Filecoin tokenomics enable providers to invest capital and
  operate at a large scale"
    />,
  ]

  return (
    <section className="why-section">
      <Container>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <h2 className="heading-h2 heading-h2-sm text-white">
              Why <br />
              <span className="text-purple">Filecoin?</span>
            </h2>
          </Col>
          <Col lg={8} md={8} sm={12} xs={12}>
            <AliceCarousel
              mouseTracking
              items={whyCards}
              responsive={carouselBreakpoints}
              animationDuration={700}
              controlsStrategy="responsive"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export const WhyCard = ({ heading, count, text }) => (
  <>
    <Card className="card-dark why-card h-100">
      <Card.Body>
        <div className="count mb-3">{count}</div>
        <Card.Title className="text-large text-large-sm">{heading}</Card.Title>
        <Card.Text className="text-normal">{text}</Card.Text>
      </Card.Body>
    </Card>
  </>
)

export default WhyFilecoin
