import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import YouTube from "react-youtube"

const Banner = () => {
  const onPlayerReady = event => {
    // access to player in all event handlers via event.target
    event.target.mute()
  }
  const onEnd = event => {
    // access to player in all event handlers via event.target
    event.target.playVideo()
  }

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      rel: 0,
      fs: 0,
      showinfo: 0,
      disablekb: 1,
      iv_load_policy: 3,
      loop: 1,
      modestbranding: 1,
      mute: 1,
    },
  }

  return (
    <section className="banner-section">
      {/* <div className="overlay" /> */}
      <div className="video-background">
        <div className="video-foreground">
          <YouTube
            videoId="xTbV4TgLEY4"
            className="video-iframe"
            opts={opts}
            onReady={onPlayerReady}
            onEnd={onEnd}
          />
        </div>
      </div>
      <Container className="position-relative">
        <Row>
          <Col xl={8} lg={9} md={12} sm={12} xs={12}>
            <h1 className="heading-h1 heading-h1-sm">
              Proven Architecture
              <br />
              Fast Deployment
            </h1>

            <p className="text-medium text-medium-sm my-3 pr-5">
              Isotechnics partners with web3-involved organizations,{" "}
              <br className="d-none d-sm-block" />
              helping them overcome the technical challenges of{" "}
              <br className="d-none d-sm-block" />
              starting and operating Filecoin storage provider nodes at scale
            </p>

            <Button
              variant="primary"
              href="#contact"
              className="rounded-lg text-medium text-medium-sm primary-btn mt-4"
            >
              Become A Filecoin Storage Provider
            </Button>
          </Col>
        </Row>

        <Row className="analytic-section">
          <Col lg={4} md={6} sm={12} xs={12}>
            <div className="analytic-box text-center d-flex flex-column h-100">
              <span className="heading-h2 heading-h2-sm">8 PiB</span>
              <span className="text-medium text-medium-sm">
                Capacity Under Management
              </span>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <div className="analytic-box text-center d-flex flex-column h-100">
              <span className="heading-h2 heading-h2-sm">2k+</span>
              <span className="text-medium text-medium-sm">FIL Earned</span>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <div className="analytic-box text-center d-flex flex-column h-100">
              <span className="heading-h2 heading-h2-sm">60 TiB</span>
              <span className="text-medium text-medium-sm">
                Daily Sealing Power
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
