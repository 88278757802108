import React from "react"
import { Container, Card } from "react-bootstrap"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

const Team = () => {
  // disclaimer: the images need to have a square aspect ratio
  const teamMembers = [
    {
      name: "Tim Yong",
      title: "CEO",
      linkedIn: "https://www.linkedin.com/in/timyong/",
      image: "/assets/images/tim.png",
    },
    {
      name: "Jason Cihelka",
      title: "CTO",
      linkedIn: "https://linkedin.com/in/jcace",
      image: "/assets/images/jason.jpeg",
    },
  ]

  const teamCards = teamMembers.map(member => {
    return (
      <Card className="card-dark team-card p-4 h-100 me-3 ms-3">
        <Card.Img variant="top" src={member.image} />
        <Card.Body className="">
          <Card.Title className="text-large text-large-sm">
            {member.name}
          </Card.Title>
          <Card.Text className="text-normal">{member.title}</Card.Text>
          <a href={member.linkedIn} target="_blank" rel="noopener noreferrer">
            <img src="/assets/svgs/linkedin.svg" alt="linkedin" />
          </a>
        </Card.Body>
      </Card>
    )
  })

  const carouselBreakpoints = {
    0: { items: 1 },
    800: { items: 2 },
    1024: { items: 3 },
  }

  return (
    <section className="team-section">
      <Container>
        <h2 className="heading-h2 heading-h2-sm text-center text-white pb-5">
          Our <span className="text-purple">Team</span>
        </h2>
        <div className="carousel-row">
          <div className="carousel-container">
            <div className="team-carousel">
              <AliceCarousel
                items={teamCards}
                responsive={carouselBreakpoints}
                animationDuration={700}
                controlsStrategy="responsive"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Team
