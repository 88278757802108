import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Hamburger from 'hamburger-react'
import { Button } from 'react-bootstrap';

const Header = () => {
  const [isOpen, setOpen] = useState(false)

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const Scroll = document.getElementsByClassName("top-navbar")[0];
    if (Scroll === undefined) {
      return;
    }
    if (currentScrollY >= 10) {
      Scroll.style.backgroundColor = "rgba(0,0,0,.7)";
      Scroll.style.boxShadow = "0 2px 2px -2px rgba(0,0,0,.2)";
    }
    if (currentScrollY < 10) {
      Scroll.style.backgroundColor = "transparent";
      Scroll.style.boxShadow = "";
    }
  };

  useEffect(() => {
    document.addEventListener(
      "scroll",
      function (e) {
        handleScroll();
      },
      {
        passive: true,
      }
    );
    return () =>
      document.removeEventListener(
        "scroll",
        function (e) {
          e.preventDefault(); // does nothing since the listener is passive
          handleScroll();
        },
        {
          passive: true,
        }
      );
  }, []);

  return (
    <>
    <Navbar 
    collapseOnSelect 
    fixed='top'
    expand="lg" 
    // bg="transparent" 
    // variant="dark" 
    className=' top-navbar'>
      <Container>
        <Navbar.Brand href="#home" className='brand-logo'>
          <img src='/assets/svgs/logo.svg' alt='logo' />
        </Navbar.Brand>
        {/* <Nav className="ms-auto align-items-center d-flex flex-row d-md-none">
        <Nav.Link href="#"><img src="/assets/svgs/twitter.svg" alt='twitter' /></Nav.Link>
            <Nav.Link href="#"><img src='/assets/svgs/discord.svg' alt='discord' /></Nav.Link>
        </Nav> */}
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" className='' >
        <Hamburger toggled={isOpen} toggle={setOpen} style={{color:"red"}} />
        </Navbar.Toggle> */}
        {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
          <Nav className="ms-auto d-flex flex-row align-items-center">
            <Button href="#contact" className='border-gradient border-0 bg-transparent contact-btn text-medium text-medium-sm d-none d-sm-block'>
              Contact Us
            </Button>
          </Nav>
          {/* <Nav>
            <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link>
          </Nav> */}
        {/* </Navbar.Collapse> */}
      </Container>
    </Navbar>
      </>
  )
}

export default Header
