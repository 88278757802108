import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap';

const ScrollButton = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
                in place of 'smooth' */
        });
    };

    return (
        <Container className='d-flex justify-content-end my-5'>
        <Button className="back-to-top bg-transparent border-0 text-uppercase" onClick={scrollToTop}>
            Back To Top
            <span className='icon'>
                <img src='/assets/svgs/arrow-top.svg' alt='back-to-top' />
            </span>
        </Button>
        </Container>
    );
}

export default ScrollButton;
