import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const ContactSection = () => {
  return (
    <section className="contact-section" id="contact">
      <Container>
        <div className="contact-bg">
          <Row className="justify-content-center">
            <Col
              xl={6}
              lg={8}
              md={10}
              sm={12}
              xs={12}
              className="px-3 inner-col text-center"
            >
              <h2 className="heading-h2 heading-h2-sm text-white">
                Become a{" "}
                <span className="text-purple">Filecoin Storage Provider</span>
              </h2>
              <div className="contact-number mt-md-5 d-flex justify-content-center">
                <a
                  href="mailto:hello@isotechnics.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-link text-large text-large-sm mt-3"
                >
                  <span className="icon">
                    <img src="/assets/svgs/Message.svg" alt="Message" />
                  </span>
                  hello@isotechnics.com
                </a>
                <a
                  href="tel:1-877-454-4430"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-link text-large text-large-sm mt-3"
                >
                  <span className="icon">
                    <img src="/assets/svgs/phone.svg" alt="Message" />
                  </span>
                  1 (877) 454-4430
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default ContactSection
